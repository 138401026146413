import React, { useEffect, useState } from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Image from "../Image"
import "../../styles/header.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons"
import ContactBlock from "../ContactBlock"
// import SocialBlock from "../SocialBlock"
import { orderServicesArray, servicesArray } from "../../data/services"
import { useScrollPosition } from "../../components/ScrollPosition"

// import { graphql } from 'gatsby'
const formPages = ["/buy-a-home", "/renew", "/refinance", "/apply-now"]

const HeaderTemplate = ({ location, contactInfo }) => {
  const isHomePage = location && location.pathname && location.pathname === "/"
  const isFormPage =
    location && location.pathname && formPages.includes(location.pathname)
  const hideHeader = isHomePage || isFormPage
  const [mobileMenuOpen, toggleMobileMenu] = useState(false)
  const [mounted, setMounted] = useState(false)
  let contact =
    contactInfo && contactInfo.frontmatter ? contactInfo.frontmatter : null
  let services = servicesArray ? servicesArray : null
  // Order
  services = orderServicesArray(services, "slug")

  const [showScrolled, setShowScrolled] = useState(false)
  const [pastHomeSection, setpastHomeSection] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const pastHome = currPos && currPos.y < -623
      setpastHomeSection(pastHome)
      if (isHomePage) {
        setShowScrolled(pastHome)
      }
    },
    [isHomePage]
  )

  if (!mounted) return null
  return (
    <header className="breakout">
      {pastHomeSection && (
        <div id="header-top">
          <div className="container">
            <ContactBlock compact={true} data={contact} />
            {/* <SocialBlock dark={true} /> */}
          </div>
        </div>
      )}
      <div
        id="header-main"
        className={pastHomeSection ? "past-home-section" : ""}
      >
        <div className="container">
          <div
            style={pastHomeSection ? { width: "10%" } : { width: "20%" }}
            className="logoContainer"
          >
            <Link to="/">
              <Image imgsrc="logo.png" alt="Ratelock.ca logo" />
            </Link>
          </div>
          <div id="right">
            {(!hideHeader || (hideHeader && showScrolled)) && (
              <button
                className="mobile-only"
                aria-label="toggle mobile menu"
                onClick={() => toggleMobileMenu(!mobileMenuOpen)}
              >
                <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
              </button>
            )}
            {hideHeader && showScrolled && (
              <ul
                className={`nav-ul ${
                  mobileMenuOpen ? "active" : "inactive"
                } home-ul`}
              >
                <li>
                  <Link to="/buy-a-home">Buying A Home</Link>
                </li>
                <li>
                  <Link to="/renew">Renew Mortgage</Link>
                </li>
                <li>
                  <Link to="/refinance">Refinance Mortgage</Link>
                </li>
              </ul>
            )}
            {!hideHeader && (
              <ul
                className={`nav-ul ${mobileMenuOpen ? "active" : "inactive"}`}
              >
                {/* <li>
                  <Link to="/getstarted">Get Started</Link>
                </li> */}
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/calculators">Calculators</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li id="expand-menu">
                  <span
                    style={{
                      pointerEvents: "none",
                      fontWeight: 500,
                      fontSize: "1em"
                    }}
                  >
                    More <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                  {services && services.length > 0 && (
                    <ul className="dropdown-content slide-in-top ">
                      {services.map((service, sId) => (
                        <li key={sId}>
                          <Link to={`/service/${service.slug}`}>
                            {service.title}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/contact">Get In Touch</Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

const Header = (props) => {
  return <HeaderTemplate {...props.data} location={props.location} />
}

const HeaderComponent = (props) => (
  <StaticQuery
    query={graphql`
      query headerQuery {
        contactInfo: markdownRemark(
          frontmatter: { templateKey: { eq: "contact-page" } }
        ) {
          frontmatter {
            title
            phone
            email
            addressLine1
            addressLine2
          }
        }
      }
    `}
    render={(data, count) => {
      return <Header location={props.location} data={data} count={count} />
    }}
  />
)

export default HeaderComponent
