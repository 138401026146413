import React, { useState } from "react"
import { validEmail } from "../../utils/helper"
import { createAgileContact } from "../../utils/agilecrm"
import queryString from "query-string"
// import { fetch } from 'whatwg-fetch'
import fetchPonyfill from "fetch-ponyfill"
const { fetch } = fetchPonyfill({})

// Newsletter
const SubscribeForm = () => {
  const initData = { name: "", email: "" }

  const [data, setData] = useState(initData) // set data
  const [honeyPot, setHoneyPot] = useState("") // spam prevention
  const [submitting, setSubmitting] = useState(false) // disable all fields
  const [error, setError] = useState({ ...initData, general: "" }) // disable all fields
  const [success, setSuccess] = useState(false) // disable all fields

  const submitForm = () => {
    setSubmitting(true)
    if (!honeyPot) {
      // if its not spam
      if (!data.name || !data.email || !validEmail(data.email)) {
        setSubmitting(false)
        setError({
          ...error,
          name: !data.name ? "Name is required" : "",
          email: !data.email
            ? "Email is required"
            : !validEmail(data.email)
            ? "Email is not valid"
            : ""
        })
        return
      } else {
        // console.log('heer?')
        setError({ ...initData })
        createAgileContact(data, "newsletter")

        return fetch(
          "https://script.google.com/macros/s/AKfycbymgWxr5GBEGKRt4NmBLJY_ugzcgOAsAqCONI2pqz66MJwD8Og/exec",
          {
            method: "POST",
            body: queryString.stringify(data),
            mode: "no-cors",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
          .then(() => {
            // console.log('and here?')
            setSubmitting(false)
            setData(initData)
            setSuccess(true)
            setTimeout(() => setSuccess(false), 3000)
          })
          .catch((err) => {
            // console.log("err", err)
            setError({
              ...error,
              general:
                "Something went wrong with the form submission. Try again"
            })
            setSubmitting(false)
          })
      }
    }
  }
  return (
    <>
      <h4>Subscribe</h4>
      <p>Get the latest updates, offers and news</p>
      <form
        className="gform"
        onSubmit={(e) => {
          e.preventDefault()
          submitForm()
        }}
      >
        <input
          id="honeypot"
          type="text"
          name="honeypot"
          value={honeyPot}
          onChange={(e) => setHoneyPot(e.target.value)}
          style={{ display: "none" }}
        />
        <input
          type="email"
          placeholder="Enter your email"
          value={data.email}
          disabled={submitting}
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
        {error && error.email && <p className="error">{error.email}</p>}

        <input
          type="name"
          placeholder="Enter your name"
          value={data.name}
          disabled={submitting}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        {error && error.name && <p className="error">{error.name}</p>}

        <button className="btn" type="submit">
          Send
        </button>
        {success && (
          <p className="success">
            Submiited Successfully. We'll reach out to your shortly
          </p>
        )}
      </form>
    </>
  )
}

export default SubscribeForm
