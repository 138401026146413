const servicesArray = [
  {
    title: "Debt Consolidation",
    slug: "debt-consolidation"
  },
  {
    title: "Business Loans",
    slug: "business-loan"
  },
  {
    title: "Loans for Emergency Needs",
    slug: "emergency-needs"
  },
  {
    title: "Loans for Higher Education",
    slug: "higher-education-loans"
  },
  {
    title: "Loans for Retired Individuals",
    slug: "retired-individuals"
  },
  {
    title: "Loans for Home Renovation",
    slug: "home-renovation-loans"
  },
  {
    title: "Loans for Self Employed",
    slug: "self-employed"
  },
  {
    title: "Loans for Tax Debt",
    slug: "tax-debt"
  }
]

const orderServicesArray = (arr, property = "value") => {
  const order = [
    "business-loan",
    "debt-consolidation",
    "emergency-needs",
    "higher-education",
    "higher-education-loans",
    "home-renovations",
    "home-renovations-loans",
    "retired-individuals",
    "tax-debt",
    "self-employed",
    "other"
  ]

  const c = order.reduce(function(r, a, i) {
    r[a] = i
    return r
  }, {})
  let ret = [...arr]
  ret.sort(function(x, y) {
    return c[x[property]] - c[y[property]]
  })

  return ret
}

export { orderServicesArray, servicesArray }
