function addCommas(num) {
  var str = num.toString().split(".")
  if (str[0].length >= 4) {
    //add comma every 3 digits befor decimal
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  /* Optional formating for decimal places
    if (str[1] && str[1].length >= 4) {
        //add space every 3 digits after decimal
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }*/
  return str.join(".")
}

function removeCommas(str) {
  return str.split(",").join("")
}

function isDateGreater(date1, date2) {
  return new Date(date1) > new Date(date2)
}

function validEmail(email) {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  return re.test(email)
}

function validName(name) {
  var regex = /^[a-zA-Z ]{2,50}$/
  return regex.test(name)
}

const _normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, "")

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return onlyNums.slice(0, 3) + "-" + onlyNums[3]
    }
    if (onlyNums.length === 7) {
      return (
        onlyNums.slice(0, 3) +
        "-" +
        onlyNums.slice(3, 6) +
        "-" +
        onlyNums.slice(6)
      )
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3)
  }
  return (
    onlyNums.slice(0, 3) +
    "-" +
    onlyNums.slice(3, 6) +
    "-" +
    onlyNums.slice(6, 10)
  )
}

const formattedDate = (date) =>
  Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit"
  }).format(date)

// Create our number formatter.
const formatCurrency = (x) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(x)
}

export {
  isDateGreater,
  addCommas,
  removeCommas,
  validEmail,
  validName,
  _normalizePhone as normalizePhone,
  formattedDate,
  formatCurrency
}
