import React from "react"
import "../styles/contact-block.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faEnvelope,
  faMapPin
} from "@fortawesome/free-solid-svg-icons"

let ContactBlock = ({ allowLocation, compact, data }) => {
  let phone = data && data.phone ? data.phone : "1-888-877-9851",
    email = data && data.email ? data.email : "hello@ratelock.ca",
    className = compact ? "compact contact-block" : "contact-block"
  return (
    <React.Fragment>
      <div className={className}>
        <a href={`tel:${phone}`} className="contact-block__inner">
          <FontAwesomeIcon icon={faPhone} />
          <div>
            <span className="contact-block__main">{phone}</span>
            <span className="contact-block__desc">Call Us</span>
          </div>
        </a>
      </div>
      <div className={`${className} email`}>
        <a href={`mailto:${email}`} className="contact-block__inner">
          <FontAwesomeIcon icon={faEnvelope} />
          <div>
            <span className="contact-block__main">{email}</span>
            <span className="contact-block__desc">Email</span>
          </div>
        </a>
      </div>
      {allowLocation && (
        <div className={className}>
          <a
            href="https://g.page/ratelock-ca?share"
            className="contact-block__inner"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faMapPin} />
            <div>
              <span className="contact-block__main">{"Burnaby, BC"}</span>
              <span className="contact-block__desc">Drop-In</span>
            </div>
          </a>
        </div>
      )}
    </React.Fragment>
  )
}

export default ContactBlock
