import React, { useState, useEffect } from "react"
const PAGE_ID = "460550891354416"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof document !== "undefined"

export function CustomerChat() {
  const [doc, setDoc] = useState(undefined)

  const [loading, setLoading] = useState(false)
  const [removeButton, setRemoveButton] = useState(false)

  useEffect(() => {
    if (!doc && isBrowser && document) {
      setDoc(document)
    }
  }, [doc, setDoc])

  const initializeScripts = () => {
    setLoading(true)
    setTimeout(() => setRemoveButton(true), 3000)
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v10.0"
      })
    }
    ;(function (d, s, id) {
      if (d) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
        fjs.parentNode.insertBefore(js, fjs)
        setLoading(false)
        setRemoveButton(true)
      }
    })(doc, "script", "facebook-jssdk")
  }
  return (
    <>
      {!removeButton && (
        <button className="facebook-button-init" onClick={initializeScripts}>
          {loading ? "Loading ..." : "Facebook Chat"}
        </button>
      )}
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="biz_inbox"
        page_id={PAGE_ID}
      ></div>
    </>
  )
}
