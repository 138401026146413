/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import { CustomerChat } from "../CustomerChat"
// import Seo from "../Seo"

function detectIE() {
  var ua = window.navigator.userAgent

  var msie = ua.indexOf("MSIE ")
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
  }

  var trident = ua.indexOf("Trident/")
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:")
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
  }

  // var edge = ua.indexOf('Edge/');
  // if (edge > 0) {
  //    // Edge => return version number
  //    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  // }

  // other browser
  return false
}

const Layout = ({ children, page, breakout, location }) => {
  const [isIE, setIsIE] = useState(false)
  useEffect(() => {
    if (detectIE()) {
      setIsIE(true)
    }
  }, [setIsIE])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div className={`overall-container container ${page}`}>
          {isIE && (
            <p
              style={{
                padding: "10px",
                fontSize: "0.9em",
                background: "#FFBABA"
              }}
            >
              This site is not optimized for Internet Explorer. Please use
              another browser like Google Chrome, Firefox, Windows Edge or
              Safari for Mac.
            </p>
          )}
          <Header
            location={location}
            siteTitle={
              data &&
              data.site &&
              data.site.siteMetadata &&
              data.site.siteMetadata.title
                ? data.site.siteMetadata.title
                : ""
            }
          />
          <main className={`main ${breakout ? "breakout" : ""}`}>
            {children}
          </main>
          <CustomerChat />
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
