import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery, Link } from "gatsby"
import ContactBlock from "../ContactBlock"
import SocialBlock from "../SocialBlock"
import SubscribeForm from "../Home/SubscribeForm"
import "../../styles/footer.css"
import Image from "../Image"
import { servicesArray, orderServicesArray } from "../../data/services"

let FooterTemplate = ({ contactInfo }) => {
  let contact =
    contactInfo && contactInfo.frontmatter ? contactInfo.frontmatter : null
  // Order
  let services = orderServicesArray(servicesArray, "slug")
  return (
    <footer id="footer">
      <div className="footer-contact">
        <ContactBlock allowLocation={true} data={contact} />
      </div>
      <div className="footer-bottom breakout">
        <div className="container">
          <div className="footer-bottom__inner">
            <div className="footer-bottom__inner-section footer-bottom__inner-left">
              <div className="logoContainer">
                <Link
                  to="/"
                  style={{
                    textDecoration: `none`
                  }}
                >
                  <Image imgsrc="logo_white.png" alt="Ratelock.ca Logo" />
                </Link>
              </div>
              <div className="tel">
                <a href={`tel:${contact.phone}`}>
                  {contact && contact.phone ? contact.phone : null}
                </a>
              </div>
              <div className="address">
                {contact && contact.addressLine1 && (
                  <p className="address-line">{contact.addressLine1}</p>
                )}
                {contact && contact.addressLine2 && (
                  <p className="address-line">{contact.addressLine2}</p>
                )}
                {/* {contact && contact.addressLine3 && (
                  <p className="address-line">{contact.addressLine3}</p>
                )} */}
              </div>
              <div>
                <SocialBlock />
              </div>
              <div className="verico">
                <Image imgsrc="verico.png" alt="Verico" />
              </div>
            </div>
            <div className="footer-bottom__inner-section footer-bottom__inner-middle">
              <div>
                <h4>Links</h4>
                {services && services.length > 0 && (
                  <ul>
                    {services.map((service, sId) => (
                      <li key={sId}>
                        <Link
                          to={`/service/${service.slug}`}
                          className="service primary-color"
                        >
                          {service.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                <h4>
                  <Link to="/blog">Blog</Link>
                </h4>
              </div>
              <div>
                <h4>
                  <Link to="/faq">FAQ</Link>
                </h4>
              </div>
            </div>
            <div className="footer-bottom__inner-section footer-bottom__inner-right">
              <SubscribeForm />
            </div>
          </div>
          <div className="privacy-and-terms">
            <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </div>
          <div className="copy">
            <span>
              © {new Date().getFullYear()} Ratelock | Deisgned and Developed by{" "}
            </span>
            <a href="https://we3solutions.ca">WE3 Solutions</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

FooterTemplate.propTypes = {
  contactInfo: PropTypes.shape({
    title: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string
  })
}

const Footer = ({ data }) => {
  return <FooterTemplate {...data} />
}
Footer.propTypes = {
  data: PropTypes.shape({
    contactInfo: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

const FooterComponent = () => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        contactInfo: markdownRemark(
          frontmatter: { templateKey: { eq: "contact-page" } }
        ) {
          frontmatter {
            title
            phone
            email
            addressLine1
            addressLine2
          }
        }
      }
    `}
    render={(data, count) => <Footer data={data} count={count} />}
  />
)

export default FooterComponent
