const createAgileContact = (data, tag) => {
  const _agile = window._agile
  // console.log("agile exists", _agile)
  if (data.email) {
    var contact = {}

    contact.email = data.email
    contact.first_name = data.name ? data.name : data.fName ? data.fName : ""
    contact.last_name = data.lName ?? ""
    contact.company = ""
    contact.title = "lead"
    contact.phone = data.phone ?? ""
    contact.website = ""
    contact.tags = tag

    _agile.create_contact(contact, {
      success: function (data) {
        console.log("success: contact created")
        _agile.set_email(data.email)
      },
      error: function (error) {
        console.log("error", error)
        // if contact already exists, just add the tag
        _agile.set_email(data.email)
        _agile.add_tag(tag, {
          success: function (data) {
            console.log("success added tag to existing contact")
          },
          error: function (data) {
            console.log("error", error)
          }
        })
      }
    })
  }
}

export { createAgileContact }
