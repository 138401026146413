import React from "react"
import "../styles/social-block.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faTwitter,
  faInstagram
  // faYoutube
} from "@fortawesome/free-brands-svg-icons"

let SocialBlock = ({ dark }) => {
  return (
    <span className={`${dark ? "dark" : "light"} social-block`}>
      <a aria-label="Facebook" href="https://www.facebook.com/ratelock.ca">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a aria-label="twitter" href="https://twitter.com/ratelock_ca">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a aria-label="instagram" href="https://www.instagram.com/ratelock.ca">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      {/* <a href="/contact"><FontAwesomeIcon icon={faYoutube} /></a> */}
    </span>
  )
}

export default SocialBlock
